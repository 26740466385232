<template>
  <div>
    <p class="title">
      <span>Fulfilment</span>
      <button
        class="button"
        title="Copy table to clipboard"
        style="float:right; font-size:15px"
        v-on:click="copyToClipboardWithHide('FulfilmentTable')"
      >
        <span class="icon is-small">
          <i class="fas fa-copy"></i>
        </span>
      </button>
    </p>

    <table class="is-size-8" id="FulfilmentTable">
      <tr>
        <th>Name</th>
        <td>{{fulfilment.Data.primary_contact.primary_contact_title}} {{fulfilment.Data.primary_contact.primary_contact_first_name}} {{fulfilment.Data.primary_contact.primary_contact_last_name}}</td>
      </tr>
      <tr>
        <th>Email</th>
        <td>{{fulfilment.Data.primary_contact.primary_contact_email}}</td>
      </tr>
      <tr>
        <th>Billing</th>
        <td>
          <span
            class="icon is-small"
            title="Copy to clipboard"
            v-if="!hideForCopy"
            style="float:right; font-size:15px; cursor:pointer"
            v-on:click="copyToClipboardWithHide('OrderFulfilmentBillingAddress')"
          >
            <i class="fas fa-copy"></i>
          </span>
          <span
            id="OrderFulfilmentBillingAddress"
            v-html="addressAsString(this.fulfilment.Data.billing_contact, 'billing_contact')"
          ></span>
        </td>
      </tr>
      <tr>
        <th>Delivery</th>
        <td>
          <span
            class="icon is-small"
            v-if="!hideForCopy"
            title="Copy to clipboard"
            style="float:right; font-size:15px; cursor:pointer"
            v-on:click="copyToClipboardWithHide('OrderFulfilmentRecipientAddreses')"
          >
            <i class="fas fa-copy"></i>
          </span>
          <span
            id="OrderFulfilmentRecipientAddreses"
            v-html="addressAsString(this.fulfilment.Data.recipient_contacts, 'recipient_contact')"
          ></span>
        </td>
      </tr>
      <tr>
        <th>Product</th>
        <td>{{fulfilment.ProductName}}</td>
      </tr>
      <tr>
        <th>Shortsite</th>
        <td>{{fulfilment.Data.products_info.campaign_ref}}</td>
      </tr>
      <tr>
        <th>Sub type</th>
        <td>{{fulfilment.Data.products_info.offer_misc.offer_type}}</td>
      </tr>
      <tr>
        <th>FF Codes</th>
        <td>
          <span
            v-if="fulfilment.Data.products_info.offer_primary_fulfilment_code && fulfilment.Data.products_info.offer_secondary_fulfilment_code"
          >{{fulfilment.Data.products_info.offer_primary_fulfilment_code}} / {{fulfilment.Data.products_info.offer_secondary_fulfilment_code}}</span>
          <span
            v-else-if="fulfilment.Data.products_info.offer_primary_fulfilment_code"
          >{{fulfilment.Data.products_info.offer_primary_fulfilment_code}}</span>
          <span v-else>No Fulfilment Codes</span>
        </td>
      </tr>
      <tr>
        <th>Date/Time</th>
        <td>{{fulfilment.Data.meta_info.transaction_datetime}}</td>
      </tr>
      <tr>
        <th>Gift</th>
        <td>{{fulfilment.Data.products_info.is_gift ? 'Yes' : 'No'}}</td>
      </tr>
      <tr>
        <th>JMAG Id</th>
        <td>{{fulfilment.Data.meta_info.order_id}}</td>
      </tr>
      <tr>
        <th class="wsn">Batch Status</th>
        <td class="wba">
          <span v-if="fulfilment.BatchJobId">
            ID: {{fulfilment.BatchJobId}}
            <span
              v-if="fulfilment.BatchJobInfo"
            >&nbsp;- {{fulfilment.BatchJobInfo.StatusAsString }} at {{fulfilment.BatchJobInfo.RunAt}}</span>
          </span>
          <span v-else>Not Sent</span>
          <div
            v-if="fulfilment.BatchJobId && fulfilment.BatchJobInfo && fulfilment.BatchJobInfo.SendLogMessage"
          >
            <b>Log:&nbsp;</b>
            <span>{{fulfilment.BatchJobInfo.SendLogMessage}}</span>
          </div>
        </td>
      </tr>
    </table>
    <!-- 
      copy could hide section before copy and unhide after is a quick flick!
                query type (canceled/non delivery/refund)
    partial or full refund-->
  </div>
</template>

<script>
import { baseMixin } from "../../../mixins/baseMixin.js";
export default {
  name: "orderFulfilmentInfo",
  props: {
    fulfilment: null
  },
  data: function() {
    return {
      hideForCopy: null
    };
  },
  mixins: [baseMixin],
  methods: {
    copyToClipboardWithHide(value) {
      this.hideForCopy = true;
      this.copyToClipboard(value);
      this.hideForCopy = false;
    }
  }
};
</script>
